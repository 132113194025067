import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

import Switch from '../../Switch';
import LoadingSpinner from '../LoadingSpinner';
//import '../../styles/partial/modal/EditEventModal.css';

const EditEventModal = ({ bandEvent, isOpen, onClose, onSave }) => {
  const [id, setId] = useState(0);
  const [bandId, setBandId] = useState(0);
  const [title, setTitle] = useState('');
  const [dateTime, setDateTime] = useState('');
  const [location, setLocation] = useState('');
  const [isOnline, setIsOnline] = useState(false);
  const [isInPerson, setIsInPerson] = useState(false);
  const [followersOnly, setFollowersOnly] = useState(false);
  const [subscribersOnly, setSubscribersOnly] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [cost, setCost] = useState(0);
  const [defaultSongSet, setDefaultSongSet] = useState('');
  const [streamLink, setStreamLink] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const formatDateTimeLocal = (dateTime) => {
    const date = new Date(dateTime);
    const pad = (num) => (num < 10 ? '0' + num : num);
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(date.getMinutes())}`;
  };

  const validate = () => {
    const newErrors = {};
    if (!title) newErrors.title = 'Title is required';
    if (!dateTime) newErrors.dateTime = 'Date and Time are required';
    if (isInPerson && !location) newErrors.location = 'Location is required for in-person events';
    if (!isOnline && !isInPerson) newErrors.eventType = 'Event must be online or in-person';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (!validate()) return;

    setLoading(true);
    
    const eventData = {
      id,
      bandId,
      title,
      dateTime: new Date(dateTime).toISOString(),
      location,
      isOnline,
      isInPerson,
      followersOnly,
      subscribersOnly,
      additionalInfo,
      cost: parseFloat(cost),
      defaultSongSet,
      streamLink,
    };
    onSave(eventData);

    setLoading(false);
  };

  useEffect(() => {
      if (bandEvent) {
        setId(bandEvent.id);
        setBandId(bandEvent.bandId);
        setTitle(bandEvent.title);
        setDateTime(formatDateTimeLocal(bandEvent.dateTime));
        setLocation(bandEvent.location);
        setIsOnline(bandEvent.isOnline);
        setIsInPerson(bandEvent.isInPerson);
        setFollowersOnly(bandEvent.followersOnly);
        setSubscribersOnly(bandEvent.subscribersOnly);
        setAdditionalInfo(bandEvent.additionalInfo);
        setCost(bandEvent.cost);
        setDefaultSongSet(bandEvent.defaultSongSet);
        setStreamLink(bandEvent.streamLink);
      }
    }, [bandEvent]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="modalContent" overlayClassName="modalOverlay">
      <h2>Add Event</h2>
      <form>
        <div className="form-group">
          <label>Title</label>
          <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
          {errors.title && <span className="error">{errors.title}</span>}
        </div>
        <div className="form-group">
          <label>Date and Time</label>
          <input type="datetime-local" value={dateTime} onChange={(e) => setDateTime(e.target.value)} required />
          {errors.dateTime && <span className="error">{errors.dateTime}</span>}
        </div>
        <div className="form-group-row">
            <div className="form-group">
            <label>
                Online Stream
                <Switch isOn={isOnline} handleToggle={() => setIsOnline(!isOnline)} switchId="online-switch" />
            </label>
            </div>
        
            <div className="form-group">
            <label>
                In Person Event
                <Switch isOn={isInPerson} handleToggle={() => setIsInPerson(!isInPerson)} switchId="inPerson-switch" />
            </label>
            </div>
            {errors.eventType && <span className="error">{errors.eventType}</span>}
        </div>
        {isOnline && (
          <div className="form-group">
            <div className="form-group">
                <label>Stream Link</label>
                <input type="url" value={streamLink} onChange={(e) => setStreamLink(e.target.value)} />
            </div>
            <div className="form-group-row">
                <div className="form-group">
                    <label>
                        Followers Only
                        <Switch isOn={followersOnly} handleToggle={() => setFollowersOnly(!followersOnly)} switchId="followers-switch" />
                    </label>
                    </div>
                    <div className="form-group">
                    <label>
                        Subscribers Only
                        <Switch isOn={subscribersOnly} handleToggle={() => setSubscribersOnly(!subscribersOnly)} switchId="subscribers-switch" />
                    </label>
                </div>
            </div>
          </div>
        )}
        
        {isInPerson && (
            <div>
                <div className="form-group">
                <label>Location</label>
                <input type="text" value={location} onChange={(e) => setLocation(e.target.value)} required />
                {errors.location && <span className="error">{errors.location}</span>}
                </div> 
                
            </div>
        )}
        <div className="form-group">
          <label>Additional Information</label>
          <textarea value={additionalInfo} onChange={(e) => setAdditionalInfo(e.target.value)} />
        </div>

        <div className="form-group-row">
        <div className="form-group">
            <label>Song Set Template</label>
            <select value={defaultSongSet} onChange={(e) => setDefaultSongSet(e.target.value)}>
                <option value="">None</option>
                {/* Add options dynamically */}
            </select>
            </div>

            <div className="form-group">
            <label>Admission Price</label>
            <input type="number" min="0" step="0.01" pattern="^\d+(?:\.\d{1,2})?$" value={cost} placeholder="0.00" onChange={(e) => setCost(e.target.value)}/>
            </div>
        </div>

        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="button-group">
            <button type="button" onClick={handleSave} className="btn-save">Save</button>
            <button type="button" onClick={onClose} className="btn-cancel">Cancel</button>
          </div>
        )}
      </form>
    </Modal>
  );
};

export default EditEventModal;
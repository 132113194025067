import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { FaPlus, FaList, FaRobot, FaFolderOpen, FaSearch } from 'react-icons/fa';

const PerformerNavSetList = () => {
  const { bandid } = useParams();

  return (
    <div className="performer-page-search-container">
      <input type="text" placeholder="Search Song" className="performer-page-search-input" />
      <FaSearch className="performer-page-search-icon" />

      <Link to={`/performers/${bandid}/song-queue`} className="performer-page-search-button">
        <FaList className="icon" /> Song Queue
      </Link>
      <Link to={`/performers/${bandid}/create-queue-template`} className="performer-page-search-button">
        <FaPlus className="icon" /> Save As Template
      </Link>
      <Link to={`/performers/${bandid}/open-queue-template`} className="performer-page-search-button">
        <FaFolderOpen className="icon" /> Open Template
      </Link>
      <a href="#ai-options" className="performer-page-search-button">
        <FaRobot className="icon" /> AI Options
      </a>
    </div>
  );
};

export default PerformerNavSetList;

import React, { useState } from 'react';
import Modal from 'react-modal';
import LoadingSpinner from '../../partial/LoadingSpinner';

const AddToQueueModal = ({ isOpen, onClose, onSave }) => {
  const [title, setTitle] = useState('');
  const [artist, setArtist] = useState('');
  const [requestedBy, setRequestedBy] = useState('');
  const [donationAmount, setDonationAmount] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSave = () => {
    setLoading(true);
    const newSong = {
      Title: title,
      Artist: artist,
      RequestedBy: requestedBy,
      DonationAmount: donationAmount,
    };
    onSave(newSong);
    setLoading(false);
  };

  const handleClose = () => {
    setTitle('');
    setArtist('');
    setRequestedBy('');
    setDonationAmount('');
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Add Song"
      className="modalContent" overlayClassName="modalOverlay"
    >
      <h2>Add Song to Queue</h2>
      <form>
        <div className="form-group">
          <label>Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Artist</label>
          <input
            type="text"
            value={artist}
            onChange={(e) => setArtist(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Requested By</label>
          <input
            type="text"
            value={requestedBy}
            onChange={(e) => setRequestedBy(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Donation Amount</label>
          <input
            type="number"
            value={donationAmount}
            onChange={(e) => setDonationAmount(e.target.value)}
          />
        </div>

        <div className="button-group">
          {loading ? (
          <LoadingSpinner />) : (
            <button type="submit" onClick={handleSave} className="btn-save">Save</button>
          )}
          <button type="button" onClick={handleClose} className="btn-cancel">Cancel</button>
        </div>
      </form>
    </Modal>
  );
};

export default AddToQueueModal;

import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import config from '../../../config';
import { useFetchWrapper } from '../../../_helpers/fetchWrapper';
import { FaPlus, FaCog, FaRobot } from 'react-icons/fa';
import EditEventModal from '../modal/EditEventModal';
import { useReload } from '../../../contexts/ReloadContext';

const PerformerNavEvents = () => {
  const { bandid } = useParams();
  const [isAddEventModalOpen, setIsAddEventModalOpen] = useState(false);
  const { reload } = useReload();
  const fetchWrapperRef = useRef(useFetchWrapper());
  

  const openAddEventModal = () => setIsAddEventModalOpen(true);

  const closeAddEventModal = () => {
    setIsAddEventModalOpen(false);
  };

  const handleSaveEvent  = async (eventData) => {
    await fetchWrapperRef.current.post(`${config.apiBaseUrl}/bands/${bandid}/shows`, eventData)
    .catch(error => {
        console.error('Error saving new event:', error);
      });

    console.log('Event data saved for band: ' + bandid, eventData);
    reload();
    closeAddEventModal();
  };

  return (
    <div className="performer-page-search-container">
      <a href="#add-event" onClick={openAddEventModal}><FaPlus className="icon" /> Add Event</a>
      <a href="#settings"><FaCog className="icon" /> Settings</a>
      <a href="#ai-options"><FaRobot className="icon" /> AI Options</a>
      <EditEventModal
        bandEvent={null}
        isOpen={isAddEventModalOpen}
        onClose={closeAddEventModal}
        onSave={handleSaveEvent}
      />
    </div>
  );
};

export default PerformerNavEvents;
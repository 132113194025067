import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaUserCircle, FaMusic, FaList, FaCalendarAlt, FaChartLine, FaVideo, FaShoppingCart, FaUsers } from 'react-icons/fa'; // Import the icons
import '../styles/TopPageNavigationBand.css';

const TopPageNavigationBand = ( {profileImageURL} ) => {
  const navigate = useNavigate();
  const { bandid } = useParams();

  if (!profileImageURL) {
    profileImageURL = sessionStorage.getItem('ProfileImageURL');
  }

  return (
    <div className="top-page-navigation-band">
      <button onClick={() => navigate('/home')} className="top-page-navigation-band-button">
        {profileImageURL ? (
          <img src={profileImageURL} alt="Profile" className="top-page-navigation-profile-image" />
        ) : (
          <FaUserCircle className="top-page-navigation-profile-image"/>
        )}
         User
      </button>
      <button onClick={() => navigate(`/performers/${bandid}`)} className="top-page-navigation-band-button"><FaUsers /> Band Profile</button>
      <button onClick={() => navigate(`/performers/${bandid}/song-list`)} className="top-page-navigation-band-button"><FaMusic /> Songs</button>
      <button onClick={() => navigate(`/performers/${bandid}/song-queue`)} className="top-page-navigation-band-button"><FaList /> Song Queue</button>
      <button onClick={() => navigate(`/performers/${bandid}/events`)} className="top-page-navigation-band-button"><FaCalendarAlt /> Events</button>
      <button onClick={() => navigate(`/performers/${bandid}/insights`)} className="top-page-navigation-band-button"><FaChartLine /> Insights</button>
      <button onClick={() => navigate(`/performers/${bandid}/media`)} className="top-page-navigation-band-button"><FaVideo /> Media</button>
      <button onClick={() => navigate(`/performers/${bandid}/store`)} className="top-page-navigation-band-button"><FaShoppingCart /> Store</button>
    </div>
  );
};

export default TopPageNavigationBand;
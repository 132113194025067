import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { FaTrash, FaArrowsAlt, FaEllipsisV, FaEye, FaEdit, FaPlay, FaCheck } from 'react-icons/fa';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import config from '../../config';
import { useFetchWrapper } from '../../_helpers/fetchWrapper';
import AddToQueueModal from './modal/AddToQueueModal';

import '../../styles/PerformerHome.css';
import styles from '../../styles/partial/PerformerSongQueue.module.css';

const PerformerSongQueue = () => {
  const { bandid } = useParams();
  const fetchWrapper = useFetchWrapper();
  const [setlist, setSetlist] = useState([]);
  const [setlistFetched, setSetlistFetched] = useState(false);
  const [ , setDraggingIndex] = useState(null);
  const [menuOpen, setMenuOpen] = useState(null);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const menuRefs = useRef({});

  const fetchSetlistData = useCallback(async () => {
    try {
      const data = await fetchWrapper.get(`${config.apiBaseUrl}/queue/${bandid}/songs`);
      const formattedData = data.map((queueItem) => ({
        RequestId: queueItem.ID,
        Position: queueItem.Position,
        Title: queueItem.song?.Title || "Unknown Title",
        Artist: queueItem.song?.Artist || "Unknown Artist",
        RequestedBy: queueItem.requested_by || "",
        DonationAmount: queueItem.donation_amount || 0,
        Status: queueItem.Status,
        SongId: queueItem.song?.ID || null,
      }));
      setSetlist(formattedData);
      setSetlistFetched(true);
    } catch (error) {
      console.error('Error fetching setlist data:', error);
      setSetlistFetched(true);
    }
  }, [bandid, fetchWrapper]);

  useEffect(() => {
    if (setlistFetched) return;
    fetchSetlistData();
  }, [setlistFetched, fetchSetlistData]);

  const handleQueueItemDelete = async (songId) => {
    try {
      await fetchWrapper.delete(`${config.apiBaseUrl}/queue/${bandid}/clear`);
      setSetlist((prevSetlist) => prevSetlist.filter((song) => song.SongId !== songId));
    } catch (error) {
      console.error('Error deleting song from setlist:', error);
    }
  };

  const handleQueueMenuEdit = (songId) => {
    console.log('Edit song:', songId);
  };

  const handleQueueItemView = (songId) => {
    console.log('View song:', songId);
  };

  const handleQueueMenuToggle = (eventId) => {
    setMenuOpen(menuOpen === eventId ? null : eventId);
  };

  const handleClickOutside = (event) => {
    if (menuRefs.current && !menuRefs.current.contains(event.target)) {
      setMenuOpen(null);
    }
  };

  const moveSong = async (dragIndex, hoverIndex) => {
    const reorderedSetlist = Array.from(setlist);
    const [movedSong] = reorderedSetlist.splice(dragIndex, 1);
    reorderedSetlist.splice(hoverIndex, 0, movedSong);

    // Reset status to "queued" if the top song is playing and gets moved
    if (dragIndex === 0 && movedSong.Status === 1) {
      movedSong.Status = 0;
    }

    try {
      const requestBody = {
        bandId: parseInt(bandid, 10),
        queuedSongs: reorderedSetlist.map((song, index) => ({
          ID: song.SongId,
          Position: index + 1,
          RequestedBy: song.RequestedBy,
          DonationAmount: song.DonationAmount,
        })),
      };
      await fetchWrapper.put(`${config.apiBaseUrl}/queue/reorder`, requestBody);
      setSetlist(reorderedSetlist);
      setDraggingIndex(hoverIndex);
    } catch (error) {
      console.error('Error reordering songs:', error);
    }
  };

  const handleAddSong = async (newSong) => {
    try {
      const requestBody = {
        band_id: parseInt(bandid, 10),
        title: newSong.Title,
        artist: newSong.Artist,
        requested_by: newSong.RequestedBy,
        donation_amount: parseFloat(newSong.DonationAmount),
      };
      const data = await fetchWrapper.post(`${config.apiBaseUrl}/queue`, requestBody);
      setSetlist((prevSetlist) => [...prevSetlist, data]);
      setAddModalOpen(false);
    } catch (error) {
      console.error('Error adding song to setlist: ', error);
    }
  };

  const handleClearQueue = async () => {
    try {
      await fetchWrapper.delete(`${config.apiBaseUrl}/queue/${bandid}/clear`);
      setSetlist([]);
    } catch (error) {
      console.error('Error clearing the queue:', error);
    }
  };
  

  const updateSongStatus = async (songId, status) => {
    console.log('Updating song status:', songId, status);
    try {
      const song = setlist.find((song) => song.SongId === songId);
      const requestBody = {
        requestId: song?.RequestId,
        songId: songId,
        bandId: parseInt(bandid, 10),
        status: status,
      };
      await fetchWrapper.post(`${config.apiBaseUrl}/queue/songstatus`, requestBody);

      // Update the setlist with the new status
      setSetlist((prevSetlist) =>
        prevSetlist.map((song) =>
          song.SongId === songId ? { ...song, Status: status } : song
        )
      );

      console.log(`Song ${songId} status updated to ${status}`);
    } catch (error) {
      console.error('Error updating song status:', error);
    }
  };

  const SongRow = ({ song, index }) => {
    const ref = React.useRef(null);

    const [{ isDragging }, drag] = useDrag({
      type: 'song',
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: () => {
        setDraggingIndex(null);
      },
    });

    const [, drop] = useDrop({
      accept: 'song',
      hover: (item, monitor) => {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }
        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        // Only perform the move when the mouse has crossed half of the item's height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }
        // Time to actually perform the action
        moveSong(dragIndex, hoverIndex);
        // Note: we're mutating the monitor item here!
        // Generally, it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex;
      },
    });

    const opacity = isDragging ? 0.25 : 1;
    drag(drop(ref));

    return (
      <div ref={ref} className={`${styles.grid_row} ${isDragging ? styles.grid_row_dragging : ''}`} style={{ opacity }}>
        <div className="grid-item grid-item-frame">
          {index === 0 && song.Status === 0 && ( // Show play icon for the top queued song
            <button onClick={() => updateSongStatus(song.SongId, 1)}>
              <FaPlay />
            </button>
          )}
          {index === 0 && song.Status === 1 && ( // Show check icon for the top playing song
            <button onClick={() => updateSongStatus(song.SongId, 3)}>
              <FaCheck />
            </button>
          )}
          <FaArrowsAlt />
        </div>
        <div className="grid-item grid-item-frame">{index + 1}</div>
        <div className={`grid-item ${menuOpen === song.SongId ? 'grid-item-highlight' : 'grid-item-frame'}`}>{song.Title}</div>
        <div className={`grid-item ${menuOpen === song.SongId ? 'grid-item-highlight' : 'grid-item-frame'}`}>{song.Artist}</div>
        <div className="grid-item grid-item-frame">{song.RequestedBy}</div>
        <div className="grid-item grid-item-frame">{song.DonationAmount}</div>
        <div className={`grid-item ${styles.contextMenuContainer}`}>
          <button onClick={() => handleQueueMenuToggle(song.SongId)}>
            <FaEllipsisV />
          </button>

          {menuOpen === song.SongId && (
            <div className={styles.contextMenu}>
              <button onClick={() => handleQueueItemView(song.SongId)}><FaEye className={styles.contextMenuIcon} /> View</button>
              <button onClick={() => handleQueueMenuEdit(song.SongId)}><FaEdit className={styles.contextMenuIcon} /> Edit</button>
              <button onClick={() => handleQueueItemDelete(song.SongId)}><FaTrash className={styles.contextMenuIcon} /> Delete</button>
            </div>
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="performer-content" ref={menuRefs}>
      <DndProvider backend={HTML5Backend}>
        <div className={styles.header_container}>
          <button onClick={() => setAddModalOpen(true)}>Add to Queue</button>
          {Array.isArray(setlist) && setlist.length > 0 && ( // Safely check if setlist is an array and has items
            <button onClick={handleClearQueue}>Clear Queue</button>
          )}
        </div>
        <div className={styles.song_grid}>
          <div className="grid-header">Move</div>
          <div className="grid-header">Position</div>
          <div className="grid-header">Title</div>
          <div className="grid-header">Artist</div>
          <div className="grid-header">Requested By</div>
          <div className="grid-header">Donation Amount</div>
          <div className="grid-header">Action</div>
          {Array.isArray(setlist) && setlist.map((song, index) => ( // Safely map over setlist
            <SongRow key={song.SongId} song={song} index={index} />
          ))}
        </div>
      </DndProvider>
      <AddToQueueModal
        isOpen={addModalOpen}
        onClose={() => setAddModalOpen(false)}
        onSave={handleAddSong}
      />
    </div>
  );
};

export default PerformerSongQueue;
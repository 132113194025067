import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { ReloadProvider, useReload } from '../../../contexts/ReloadContext';

import '../../../styles/partial/layout/BandLayoutPage.css';

import Logo from '../../Logo';
import TopPageNavigationBand from '../../TopPageNavigationBand';

import '../../../App.css'
import '../../../styles/partial/modal/modal.css'

const BandLayoutPage = ({ children, previousPage, navigation }) => {
  return (
    <ReloadProvider>
      <BandLayoutPageContent children={children} previousPage={previousPage} navigation={navigation} />
    </ReloadProvider>
  );
};

const BandLayoutPageContent = ({ children, previousPage, navigation }) => {
  const navigate = useNavigate();
  const { bandid } = useParams();
  const { reloadKey } = useReload();

  if (bandid && previousPage) {
    // Modify the previousPage URL to include the ID
    previousPage = previousPage.replace(':bandid', bandid);
  }

  return (
    <div className="performer-page">
      <Logo />
      <TopPageNavigationBand />
      <div className="performer-page-wrapper">
        <div className="performer-page-content">
          {previousPage && (
            <div className="back-arrow" onClick={() => navigate(previousPage)}>
              <FaArrowLeft /> Back
            </div>
          )}
          {React.cloneElement(children, { reloadKey })}
        </div>

        {navigation && (
          <div className="performer-page-right-side-navigation">
            {React.cloneElement(navigation, { reloadKey })}
          </div>
        )}
      </div>
    </div>
  );
};

export default BandLayoutPage;
import React, { useEffect, useRef, useState, useMemo } from 'react';
import '../styles/LoginPage.css';  // Import styles
import { FaGoogle, FaTwitch, FaSpotify, FaAndroid } from 'react-icons/fa';
import Logo from './Logo';
import config from '../config';
import CryptoJS from 'crypto-js';

import LoadingSpinner from './partial/LoadingSpinner';

function LoginPage() {

  const { apiBaseUrl } = config;
  const hasFetched = useRef(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const errors = useMemo(() => {
    return {
      unauthorized: "User is not authorized."
    };
  }, []);

  useEffect(() => {
    //if the url has a query parameter for error, display the error
    const q = new URLSearchParams(window.location.search);
    if (q.has('error')) {
      const message = q.get('error').toLowerCase();
      setError(errors[message] || "An error occurred.");
    }

    if (!hasFetched.current) {
      hasFetched.current = true;

      // Generate a random 32-character alphanumeric string
      const generateVerifier = () => {
        const array = new Uint32Array(32);
        window.crypto.getRandomValues(array);
        return Array.from(array, dec => ('0' + dec.toString(36)).substr(-2)).join('');
      };

      // Create an S256 hash of the random string
      const generateChallenge = async (verifier) => {
        const hash = CryptoJS.SHA256(verifier);

        return CryptoJS.enc.Base64.stringify(hash)
          .replace(/\+/g, '-')
          .replace(/\//g, '_')
          .replace(/=+$/, '');
      };

      const verifier = generateVerifier();
      
      generateChallenge(verifier).then(challenge => {
        localStorage.setItem('verifier', verifier);
        localStorage.setItem('challenge', challenge);
        setLoading(false);
      });
    }

  }, [errors]);

  const handleGoogleLogin = () => {
    const challenge = localStorage.getItem('challenge');
    localStorage.removeItem('challenge');
    localStorage.setItem('oauth-provider', 'google');
    window.location.href = `${apiBaseUrl}/auth/google/login?challenge=${challenge}`;
  };

  const handleTwitchLogin = () => {
    const challenge = localStorage.getItem('challenge');
    localStorage.removeItem('challenge');
    localStorage.setItem('oauth-provider', 'twitch');
    window.location.href = `${apiBaseUrl}/auth/twitch/login?challenge=${challenge}`;
  };
  

  const handleDevLogin = () => {
    localStorage.setItem('oauth-provider', 'dev');
    window.location.href = `${apiBaseUrl}/auth/dev/login`;
  };

  return (
    <div className="login-page">
      <Logo />


      {error && (
        <div className="login-text"> {error} </div>
      )}


      {loading ? (
        <LoadingSpinner />
      ) : (     
      <div className="login-page-login-buttons">
        <button className="login-page-login-button login-page-google-login-button"
          onClick={handleGoogleLogin} disabled={loading}
        >
          <FaGoogle className="login-page-login-icon" />
          <span className="login-text">Login with Google</span>
        </button>
        <button className="login-page-login-button login-page-twitch-login-button"
        onClick={handleTwitchLogin} disabled={loading}>
          <FaTwitch className="login-page-login-icon" />
          <span className="login-text">Login with Twitch</span>
        </button>
        <button className="login-page-login-button login-page-spotify-login-button" disabled={loading}>
          <FaSpotify className="login-page-login-icon" />
          <span className="login-text">Login with Spotify</span>
        </button>
        {process.env.NODE_ENV === 'development' && (
        <button className="login-page-login-button login-page-dev-login-button"
        onClick={handleDevLogin} disabled={loading}>
          <FaAndroid className="login-page-login-icon" />
          <span className="login-text">Login with Dev</span>
        </button>
        )}
      </div>

    )}
    </div>
  );
}

export default LoginPage;
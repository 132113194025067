import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { FaEdit, FaEllipsisV, FaTrash } from 'react-icons/fa';

import config from '../../config';
import { useFetchWrapper } from '../../_helpers/fetchWrapper';
import EditEventModal from './modal/EditEventModal';
import styles from '../../styles/partial/PerformerEvents.module.css';
import { useReload } from '../../contexts/ReloadContext';

const PerformerEvent = ( {reloadKey} ) => {
  const { bandid } = useParams();
  const fetchWrapper = useFetchWrapper();

  const [futureEvents, setFutureEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [showFutureEvents, setShowFutureEvents] = useState(true);
  const [menuOpen, setMenuOpen] = useState(null);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [eventsFetched, setEventsFetched] = useState(false);
  const { reload } = useReload();

  const lastReloadKeyRef = useRef(null);
  const menuRefs = useRef({});

  const fetchEvents = useCallback(async () => {
    const events = await fetchWrapper.get(`${config.apiBaseUrl}/bands/${bandid}/shows`);

    var future = [];
    var past = [];

    console.log(events);
    if(events.futureEvents && events.futureEvents.length > 0) {
      future = events.futureEvents;
    }

    if(events.pastEvents && events.pastEvents.length > 0) {
      past = events.pastEvents;
    }

      setFutureEvents(future);
      setPastEvents(past);
      setEventsFetched(true);
  }, [bandid, fetchWrapper]);

  useEffect(() => {
    if(lastReloadKeyRef.current !== reloadKey) {
      setEventsFetched(false);
      lastReloadKeyRef.current = reloadKey;
    }

    if (eventsFetched) return;
    fetchEvents();

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [eventsFetched, fetchEvents, reloadKey]);

  const handleEventMenuToggle = (eventId) => {
    setMenuOpen(menuOpen === eventId ? null : eventId);
  };

  const handleEventEdit = (event) => {
    setCurrentEvent(event);
    setEditModalOpen(true);
    setMenuOpen(null);
  };

  const handleFutureEventDelete = async (eventId) => {
    try {
      await fetchWrapper.delete(`${config.apiBaseUrl}/bands/${bandid}/shows/${eventId}`);
      setFutureEvents((prevEvents) => prevEvents.filter((event) => event.id !== eventId));
      setMenuOpen(null);
    } catch (error) {
      console.error('Error deleting event:', error);
    }
  };

  const handleClickOutside = (event) => {
    if (menuRefs.current && !menuRefs.current.contains(event.target)) {
      setMenuOpen(null);
    }
  };

  const closeModal = () => {
    setEditModalOpen(false);
    setCurrentEvent(null);
  };

  const handleEventSave = async (updatedEvent) => {
    try {

      // Convert the date and time to UTC
      updatedEvent.dateTime = new Date(updatedEvent.dateTime).toISOString();
      updatedEvent.cost = parseFloat(updatedEvent.cost);

      await fetchWrapper.put(`${config.apiBaseUrl}/bands/${bandid}/shows/${updatedEvent.id}`, updatedEvent);
      setFutureEvents((prevEvents) =>
        prevEvents.map((event) =>
          event.ID === updatedEvent.id ? updatedEvent : event
        )
      );
      closeModal();
      reload();
    } catch (error) {
      console.error('Error saving event:', error);
    }
  };

  return (
    <div className="performer-content" ref={menuRefs}>
      <div className={styles.events_toggle}>
      {showFutureEvents ? (
        <button
        className={`toggle-button`}
        onClick={() => setShowFutureEvents(false)}
      >
        Show Past Events
      </button>
        ) : (
        <button
          className={`toggle-button`}
          onClick={() => setShowFutureEvents(true)}
        >
          Show Upcoming Events
        </button>
        )}
      </div>
      <div className="events-container">
      {showFutureEvents ? (
        <div className="events-section">
          <h2>Upcoming Events</h2>
          <div className={styles.song_grid}>
            <div className="grid-header">Date</div>
            <div className="grid-header">Title</div>
            <div className="grid-header">Location</div>
            <div className="grid-header">Action</div>
              {futureEvents.map(event => (
                <React.Fragment key={event.id}>
                  <div className="grid-item grid-item-frame event-date">{new Date(event.dateTime).toLocaleDateString()}</div>
                  <div className="grid-item grid-item-frame event-title">{event.title}</div>
                  <div className="grid-item grid-item-frame event-location">{event.location}</div>
                  <div className={`grid-item ${styles.contextMenuContainer}`}>
                    <button onClick={() => handleEventMenuToggle(event.id)}>
                        <FaEllipsisV />
                    </button>
                  
                    {menuOpen === event.id && (
                      <div className={styles.contextMenu}>
                        <button onClick={() => handleEventEdit(event)}><FaEdit className={styles.contextMenuIcon} /> Edit</button>
                        <button onClick={() => handleFutureEventDelete(event.id)}><FaTrash className={styles.contextMenuIcon} /> Delete</button>
                      </div>
                    )}
                  </div>
                </React.Fragment>
              ))}
            </div>
        </div>
      ) : (
        <div className="events-section">
          <h2>Past Events</h2>
          <div className={styles.song_grid}>
            <div className="grid-header">Date</div>
            <div className="grid-header">Title</div>
            <div className="grid-header">Location</div>
            <div className="grid-header">Action</div>
            {pastEvents.map(event => (
              <React.Fragment key={event.id}>
                <div className="grid-item grid-item-frame event-date">{new Date(event.dateTime).toLocaleDateString()}</div>
                <div className="grid-item grid-item-frame event-title">{event.title}</div>
                <div className="grid-item grid-item-frame event-location">{event.location}</div>
                <button className="grid-item event-button">Recap</button>
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
      </div>
      <EditEventModal
        bandEvent={currentEvent}
        isOpen={editModalOpen}
        onClose={closeModal}
        onSave={handleEventSave}
      />
    </div>
  );
};

export default PerformerEvent;